.widget_review {

	line-height: 1.375;
	border: 3px solid theme(review, base);
	box-shadow: none;

	p {
		margin-bottom: 10px;
	}

	cite {
		font-size: 115%;
		font-style: normal;
		line-height: 1;
	}

	svg {
		fill: theme(review, base);
	}

}